import React from "react"
import { graphql, Link } from 'gatsby'
import Seo from '../components/SEO'

const Home = ({ data }) => {

  const { nodes } = data.allMarkdownRemark

  return (
      <div className="wrapper">
        <Seo></Seo>
        <header>
          <h1 className="large-title">
            <Link to="/">Warp Barrel</Link>
          </h1>
        </header>
        <main>
          {
            nodes.map((node) => {
              const { frontmatter, id } = node
              const { title, date, description, slug } = frontmatter
              const url = `${slug}/`
              return (
                <article key={id} className="list-item">
                  <header>
                    <h2>
                      <Link to={url}>{title}</Link>
                    </h2>
                    <small>{date}</small>
                    <p>{description}</p>
                  </header>
                </article>
              )
            })
          }
        </main>
    </div>
  )
}

export default Home

export const query = graphql`
  query HomeQuery {
    allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}) {
      nodes {
        frontmatter {
          title
          slug
          date(formatString: "MMM DD, YYYY")
          description
        }
        id
        html
      }
    }
  }
`